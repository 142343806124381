@use 'functions' as *;
//===================================================
//	content setting
//===================================================

//mediaquery
$grid-num:(
  xxs: 0,        //0から　最小デバイス
  xs: 376,     //iPhone
  sm: 576,     //スマホ
  md: 768,     //タブレット iPadmini
  lg: 1080,    //PCモニター small
  xl: 1440,    //PCモニター
  xxl: 1640,    //Large PC
  full: 1920
) !default;

$grid-breakpoints: ( 
  xxs: 0,        //0から　最小デバイス
  xs: 376px,     //iPhone
  sm: 576px,     //スマホ
  md: 768px,     //タブレット iPadmini
  lg: 1176px,    //PCモニター small
  xl: 1553px,    //PCモニター
  xxl: 1753px,    //Large PC
  full: 1921px
) !default;

@include assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include assert-starts-at-zero($grid-breakpoints);

//content_size
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1536px,
  xxl: 1736px,
  full: 1920px
) !default;

@include assert-ascending($container-max-widths,"$container-max-widths");

// header_hight
$header_hight_sp: 64px;
$header_hight_pc: 100px;

// header breakpoints
$header-breakpoints: 1296px;

// header logo max width
$h_logo_max_width_pc: 214px;
$h_logo_max_width_sp: 160px;

//content_size
$cont_w:	map-get($container-max-widths,xl);//メインコンテンツサイズ
$aside_w:	240px;//サイドサイズ

//コンテンツ全体に余白をつけるか（余白ありきでデザインされていない場合true）
$contmargin: (
  sp:5%,
  tb:8%,
  pc:48px,
  full: 5.2vw
) !default;
$side_use: none; //サイドどこで使うか all,lv2,blog,none
$side_position: left; //サイドの位置


//layout
$base_pad: 4%;
$base_mar: 1rem;

$maxcol:12 !default; //カラム数
$col-margin:20px !default; //カラム間余白

//grid 用のカラム設定
$grid-columns:  12 !default;
$grid-gutter-width: 30px !default;

$spacer: 1rem !default;
$spacers: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .5),
    2: ($spacer * .75),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 5),
    6: ($spacer * 7),
    7: ($spacer * 10)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


//画像のアスペクト比
$img-horizontal:3; //横
$img-vertical:2; //縦
$img-hight:floor-decimal(calc($img-vertical/$img-horizontal)*100%);

